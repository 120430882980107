import React from 'react';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import geojson from './roman.json'; // Replace with your GeoJSON file


function App() {
	const worldStyle = {
		fillColor: 'blue',
		fillOpacity: 0.8
	  };

	  const waterStyle = {
		fillColor: 'darkblue',
		fillOpacity: 1
	  };

	  const onFeatureMouseOver = (event) => {
		const layer = event.target;
		layer.bindPopup('Hello, world').openPopup();
	  };

	  return (
		<MapContainer
			center={[0, 0]}
			zoom={2}
			style={{ width: '100%', height: '600px' }}
			zoomControl={false} // Prevent the default zoom controls
			doubleClickZoom={false} // Disable zooming on double-click
			scrollWheelZoom={false} // Disable zooming using the mouse scroll wheel
			>
		  <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution="© OpenStreetMap contributors" />
		  <GeoJSON data={geojson} style={worldStyle} onEachFeature={(feature, layer) => layer.on('mouseover', onFeatureMouseOver)} />

		</MapContainer>
	  );
}

export default App;
